<template>
    <layout>
        <div class="card container p-5">
            <div class="row ">
                <div class="col-12">
                    Explore Page
                </div>
                <div class="col-12">
                    <FormulateForm name="AwardAction" v-model="values" :schema="schema" @submit="submitHandler">



                        <div class="row col-12 mb-2" v-if="values.filter_key == 'project_type'">
                            <label>Project Type</label>
                            <multiselect v-model="filter_key" label="name" placeholder="Type to search" track-by="_id"
                                open-direction="bottom" :multiple="false" :searchable="true" :options="property_types"
                                :internal-search="true" :clear-on-select="false" :close-on-select="true"
                                :options-limit="10" :limit="1" :max-height="600" :show-no-results="true"
                                :blockKeys="['Delete']" :hide-selected="true">
                            </multiselect>

                        </div>
                        <div class="row col-12 mb-2" v-if="values.filter_key == 'city'">
                            <label>Country</label>
                            <multiselect v-model="selectedCountry" label="name" placeholder="Type to search"
                                track-by="_id" open-direction="bottom" :multiple="false" :searchable="true"
                                :options="countryList" :internal-search="true" :clear-on-select="false"
                                :close-on-select="true" :options-limit="10" :limit="1" :max-height="600"
                                :show-no-results="true" :blockKeys="['Delete']" :hide-selected="true">
                            </multiselect>

                        </div>
                        <div class="row col-12 mb-2" v-if="values.filter_key == 'city'">
                            <label>City</label>

                            <multiselect v-model="filter_key" label="name" placeholder="Type to search" track-by="_id"
                                open-direction="bottom" :multiple="false" :searchable="true" :options="filterOptions"
                                :internal-search="true" :clear-on-select="false" :close-on-select="true"
                                :options-limit="100" :limit="1" :max-height="600" :show-no-results="true"
                                :blockKeys="['Delete']" :hide-selected="true">
                            </multiselect>

                        </div>
                        <FormulateInput type="submit" class="float-right">

                            <div>
                                <b-spinner v-if="explore_saving" class="vueformulate-loader"></b-spinner>
                                <span v-else> Save </span>
                            </div>
                        </FormulateInput>
                    </FormulateForm>
                </div>
            </div>
        </div>
    </layout>
</template>

<script >
import Layout from "../../layouts/main";
import { explore } from "./data/configurations"
import { countries } from "@/config/api/countries";
import { cities } from "@/config/api/cities";

import { property_types } from "@/config/api/property_types";

import Multiselect from "vue-multiselect";

export default {
    components: {
        Layout,
        Multiselect
    },
    created() {
        this.loadExploreSettings().then(() => {
            this.getProjectTypes();
        })

    },
    methods: {
        async submitHandler(data) {
            this.explore_saving = true
            let api = explore.create;
            api.data = { ...data, filter_value: (this.filter_key && this.filter_key._id) }
            var res = await this.generateAPI(api);
            console.log(res.data)
            this.explore_saving = false;
            this.loadExploreSettings();
        },
        async getCityNames() {
            let api = cities.cityByCountry;
            api.id = (this.selectedCountry && this.selectedCountry._id)
            var res = await this.generateAPI(api);
            this.filterOptions = res.data.cities;

        },
        async getCountryNames() {
            const api = countries.get;
            let res = await this.generateAPI(api)
            this.countryList = res.data.countries;
        },
        async getProjectTypes() {
            const api = property_types.getAllWithoutPagination;
            let res = await this.generateAPI(api)
            this.property_types = res.data.property_types;
            for (const key in this.property_types) {
                if (Object.hasOwnProperty.call(this.property_types, key)) {
                    const element = this.property_types[key];
                    if (element._id == (this.filter_key && this.filter_key._id)) {
                        this.filter_key = element;
                    }
                }
            }
        },
        async loadExploreSettings() {
            let api = explore.get;
            var res = await this.generateAPI(api);
            if (res.data.exploreProjectFilter) {
                for (const key in Object.keys(res.data.exploreProjectFilter.sort)) {
                    if (Object.hasOwnProperty.call(Object.keys(res.data.exploreProjectFilter.sort), key)) {
                        const element = Object.keys(res.data.exploreProjectFilter.sort)[key];
                        if (res.data.exploreProjectFilter.sort[element]) {
                            this.values.sort_key = element
                            this.values.sort_value = `${res.data.exploreProjectFilter.sort[element]}`
                        }
                    }
                }
                for (const key in Object.keys(res.data.exploreProjectFilter.filter)) {
                    if (Object.hasOwnProperty.call(Object.keys(res.data.exploreProjectFilter.filter), key)) {
                        const element = Object.keys(res.data.exploreProjectFilter.filter)[key];

                        if (res.data.exploreProjectFilter.filter[element]) {
                            this.values.filter_key = element
                            if(!this.filter_key)
                                this.filter_key = { _id: res.data.exploreProjectFilter.filter[element], }
                            if (element == "city") {
                                //code here
                                const cityApi = cities.cityById;

                                cityApi.id = res.data.exploreProjectFilter.filter[element];

                                await this.generateAPI(cityApi)
                                    .then((res) => {
                                        this.selectedCountry = res.data.city.country;
                                        this.filter_key = res.data.city;
                                    });
                            }
                        }

                    }
                }
            }
        }
    },
    data() {
        return {
            filter_key: "",
            filterOptions: [],
            selectedCountry: "",
            countryList: [],
            property_types: [],
            explore_saving: false,

            values: {},
            schema: [

                {
                    index: 2,
                    type: "select",
                    name: "sort_key",
                    label: "Sorting By",
                    options: [
                        { label: 'City', value: "city" },
                        { label: 'Team-Members', value: "number_of_team_members" },
                        { label: 'Companies', value: "number_of_companies" },
                        { label: 'Views', value: "number_of_views" },
                        { label: 'Rating', value: "rating" },
                    ]

                },
                {
                    index: 3,
                    type: "select",
                    name: "sort_value",
                    label: "Sorting Order",
                    options: [
                        { label: 'A-Z', value: "1" },
                        { label: 'Z-A', value: "-1" },

                    ]

                },
                {
                    index: 1,
                    type: "select",
                    name: "filter_key",
                    label: "Filter By",
                    options: [
                        { label: 'City', value: "city" },
                        { label: 'Project Type', value: "project_type" },
                    ]
                },

            ],
        }
    },
    watch: {
        selectedCountry() {
            this.getCityNames();
        },
        values(newValue, oldValue) {
            if (newValue.filter_key != (oldValue && oldValue.filter_key)) {
                this.filter_key = '';
                switch (newValue.filter_key) {
                    case "city":
                        this.getCountryNames();
                        break;
                    case "project_type":
                        if (this.property_types.length == 0) {
                            this.getProjectTypes();
                        }
                        break;
                    default:
                        break;
                }
            }
        },
    },



};

</script>

<style >

</style>