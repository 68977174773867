export const explore = {
    get: {
        url: "admin/project/getExploreProjectFilter",
        method: "GET",
        id: null,
        params: null,
    },
    create: {
        url: "project/setNearbyFilter",
        method: "PUT",
        data: null,
    }
}